import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Auth, I18n } from 'aws-amplify';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { FC, useContext, useEffect, useState } from 'react';
import ClientsClient from 'src/clients/ClientsClient';
import { AuthLinkActionButton } from 'src/legacy/components/Auth/AuthLinkActionButton';
import { ClientAuthContainer } from 'src/legacy/components/Auth/ClientAuthContainer';
import { authScreenStyles } from 'src/legacy/components/Auth/styles';
import { IAuthenticatorReturn } from 'src/legacy/components/AwsAmplify';
import Button from 'src/legacy/components/Button';
import RowDivider from 'src/legacy/components/RowDivider';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { BaseTextField, PasswordField } from 'src/legacy/components/TextField';
import { GoogleAuthButton } from 'src/legacy/components/UI/Buttons/GoogleAuthButton';
import { LOGIN_PAGE } from 'src/constants';
import {
  AUTH_STATES,
  SIGNED_IN_WITH_GOOGLE_USER_KEY,
} from 'src/constants/authConsts';
import {
  AuthErrorCodes,
  AuthErrors,
} from 'src/constants/errorConsts/errorCodesConsts';
import { PortalConfigContext, RouteContext } from 'src/context';
import history from 'src/history';
import { useAppDispatch } from 'src/hooks/useStore';
import { ClientFormData } from 'src/store/clients/types';
import { alertSnackbar } from 'src/store/ui/actions';
import { GraySmall } from 'src/theme/colors';
import {
  PasswordInputValidation,
  SignInWithGoogle,
  getPreferredUsername,
  isPassword,
  logoutFromHostedUi,
} from 'src/utils/AuthUtils';
import { CognitoUserWithAttributes } from 'src/utils/CognitoUtils';
import { ensureApiError } from 'src/utils/Errors';
import { isEmptyString, removeSpaceToLowerCase } from 'src/utils/StringUtils';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { useSendMagicLinkHandler } from 'src/legacy/components/Auth/useSendAccessLinkHandler';

type RegisterFormValues = {
  fullName: string;
  password: string;
  companyName: string;
  email: string;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...authScreenStyles(theme),
  }),
);

const validAuthState = ['signUp'];

const Register: FC<IAuthenticatorReturn> = (props) => {
  const { onStateChange, authState } = props;
  const { query } = useContext(RouteContext);
  const { action } = query;
  const portalConfig = useContext(PortalConfigContext);
  const { authPreferences } = portalConfig;
  const enableMagicLinks = authPreferences?.enableMagicLinks;
  const [googleAuthClientData, setGoogleAuthClientData] = useState<
    ClientFormData | undefined
  >(undefined);
  const { handleSendMagicLink: sendMagicLink, isSendingMagicLink } =
    useSendMagicLinkHandler();
  const googleSignedInUser = window.localStorage.getItem(
    SIGNED_IN_WITH_GOOGLE_USER_KEY,
  );

  const isGoogleSignUpFlow = query.code && query.state;

  // this tells whether there is a google authenticated user within the sign up
  // flow.
  const hasGoogleSignedInUser =
    googleSignedInUser != null && !isEmptyString(googleSignedInUser);
  const getPasswordValidation = () => {
    // when it is magic link flow or google auth flow,
    // then the password is not required
    if (googleAuthClientData) {
      return Yup.string().nullable();
    }

    if (enableMagicLinks) {
      // if magic link is enabled, we need to keep validating the password
      // even if it is not provided. The password is optional, but if provided
      // it should be a valid password.
      return isPassword;
    }
    return PasswordInputValidation;
  };

  const [showLoading, setShowLoading] = useState(false);
  const schema = React.useMemo(() => {
    return Yup.object().shape({
      fullName: Yup.string()
        .trim()
        .max(64)
        .required('Full Name is required')
        .test('is-valid-name', 'Please provide a full name', (value) => {
          if (!value) return false;
          return value.split(' ').length > 1;
        }),
      companyName: Yup.string().trim().max(100),
      email: Yup.string()
        .email('Invalid email')
        .max(100)
        .required('Email is required'),
      password: getPasswordValidation(),
    });
  }, [enableMagicLinks, googleAuthClientData]);
  const classes = useStyles();

  const [isSignUpLoading, setIsSignUpLoading] = useState(false);

  const handleGoToSignIn = async () => {
    const signedInWithGoogle = window.localStorage.getItem(
      SIGNED_IN_WITH_GOOGLE_USER_KEY,
    );

    // if the user is already signed in with google and
    // they are going to login flow, then we need to sign them out.
    if (signedInWithGoogle) {
      logoutFromHostedUi(
        portalConfig,
        `${portalConfig.AWS.Auth.oauth?.redirectSignOut}?app-error=signup_cancelled`,
      );
    }
    onStateChange(AUTH_STATES.SIGN_IN);
    history.push(LOGIN_PAGE);
  };

  useEffect(() => {
    onStateChange(AUTH_STATES.SIGN_UP);
    if (portalConfig.features.signupDisabled) {
      handleGoToSignIn();
    }
  }, []);

  const formikRef = React.useRef<FormikProps<RegisterFormValues>>(null);
  const areCompaniesDisabled = portalConfig.features.disableCompanies ?? false;
  const googleAuthFeatureEnabled = !portalConfig.features.disableGoogleSignIn;

  const googleAuthState = new URLSearchParams({
    portalId: portalConfig.portalHeader,
    origin: window.location.origin,
    action: 'signup',
  });

  const dispatch = useAppDispatch();
  const completeSignup = async (clientUserData: ClientFormData) => {
    try {
      await ClientsClient.addClient(clientUserData, true);

      // if the user is signing up with google auth then we need to
      // auto sign them in.
      const signedInWithGoogle = window.localStorage.getItem(
        SIGNED_IN_WITH_GOOGLE_USER_KEY,
      );

      if (signedInWithGoogle) {
        await SignInWithGoogle(
          new URLSearchParams({
            portalId: portalConfig.portalHeader,
            origin: window.location.origin,
          }).toString(),
        );
        return true;
      }

      if (clientUserData.cognitoEmail) {
        const preferredUsername = getPreferredUsername({
          email: clientUserData.cognitoEmail,
          viewMode: portalConfig.viewMode,
          portalId: portalConfig.portalHeader,
        });

        const signInResponse = await Auth.signIn(
          preferredUsername,
          clientUserData.password,
        );

        if (signInResponse.userConfirmed) {
          onStateChange(AUTH_STATES.SIGNED_UP, {
            username: clientUserData.userId,
            password: clientUserData.password,
          });
        } else {
          // TODO: to support confirmation on sign-up we need update this item
          onStateChange(AUTH_STATES.CONFIRM_SIGN_UP, {
            challengeName: 'CONFIRM_SIGN_UP',
            username: clientUserData.userId,
            password: clientUserData.password,
            signUpPayload: signInResponse,
          });
        }
      }
      return true;
    } catch (e) {
      // when the user tries to sign up with an email that already exists
      // we need to auto sign them in with google auth
      const { message } = ensureApiError(e);
      if (
        message.includes(AuthErrorCodes.googleAccountExists) ||
        message.includes(AuthErrorCodes.accountAlreadyExists)
      ) {
        // TODO: here we call federatedSignIn in order
        // to handle the auto sign in flow. This will
        // create an extra redirect that can be avoided
        // in the future by using the handleStateChange
        // in client login flow which does the same thing.
        const signedInWithGoogle = window.localStorage.getItem(
          SIGNED_IN_WITH_GOOGLE_USER_KEY,
        );

        // auto sign in with google auth if the user is already signed in with google
        if (signedInWithGoogle) {
          await SignInWithGoogle(
            new URLSearchParams({
              portalId: portalConfig.portalHeader,
              origin: window.location.origin,
            }).toString(),
          );
        } else {
          // if the user is not trying to signup with google auth
          // this means that they are trying to sign up with an email
          // that is already in use. So we need to show an error message
          // to the user.
          dispatch(
            alertSnackbar({
              errorMessage: AuthErrors.emailAlreadyInUse,
            }),
          );
        }
      }

      dispatch(
        alertSnackbar({
          errorMessage:
            'Failed to signup with email provided. Please try again. If the problem persists, contact support.',
        }),
      );
      setIsSignUpLoading(false);
      return false;
    }
  };

  /**
   * After user is authenticated in google hosted ui,
   * this function will grab the federated authenticated user info
   * to fill them in the register form. And it will proceed
   * to the next register form step.
   */
  const completeClientGoogleSignup = async () => {
    let user: CognitoUserWithAttributes | null = null;
    setShowLoading(true);
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      setShowLoading(false);
      console.error('error while getting the authenticated user', error);
      return;
    }

    if (!user) {
      return;
    }
    const {
      email: cognitoEmail,
      given_name: cognitoFirstName,
      family_name: cognitoLastName,
      picture,
    } = user.attributes;

    const clientData = {
      userId: v4(),
      cognitoEmail,
      cognitoFirstName,
      cognitoLastName,
      companyName: '',
      sendInvite: false,
      noPasswordSet: true,
      externalAuthProviders: [CognitoHostedUIIdentityProvider.Google],
      avatarImageUrl: picture,
    };
    setGoogleAuthClientData(clientData);

    if (portalConfig.features.disableCompanies) {
      await completeSignup(clientData);
      return;
    }

    setShowLoading(false);
    if (!formikRef.current) {
      return;
    }
    formikRef.current.setFieldValue(
      'fullName',
      `${cognitoFirstName} ${cognitoLastName}`,
    );
    formikRef.current.setFieldValue('email', cognitoEmail);
    setShowLoading(false);
  };

  // this effect is triggered to complete the google signup flow
  // when the action query param is set to signup.
  React.useEffect(() => {
    if (action === 'signup') {
      completeClientGoogleSignup();
    }
  }, [action]);

  const handleSignup = async (
    values: FormikValues,
    { setStatus }: FormikHelpers<RegisterFormValues>,
  ) => {
    if (enableMagicLinks && !values.password && !isGoogleSignUpFlow) {
      await sendMagicLink(
        'signup',
        values.email,
        values.fullName,
        values.companyName,
      );
      return;
    }
    setIsSignUpLoading(true);
    const username = v4();
    const email = removeSpaceToLowerCase(values.email);
    const givenName = values.fullName.split(' ')[0].trim();
    const familyName = values.fullName.split(' ')[1].trim();
    const companyName = values.companyName.trim();
    let clientUserData: ClientFormData = {
      userId: username,
      cognitoEmail: email,
      cognitoFirstName: givenName,
      cognitoLastName: familyName,
      companyName,
      sendInvite: false,
      password: values.password,
    };

    // if it is a google auth user then we need to set the
    // noPasswordSet property to true and set the externalAuthProviders
    // to be google.
    clientUserData = {
      ...clientUserData,
      noPasswordSet: googleAuthClientData?.noPasswordSet ?? false,
      externalAuthProviders: googleAuthClientData?.externalAuthProviders,
      avatarImageUrl: googleAuthClientData?.avatarImageUrl,
    };

    const success = await completeSignup(clientUserData);
    if (!success) {
      setStatus(false);
    }
  };

  if (!validAuthState.includes(authState)) return null;

  // In this case, the progress bar from the parent component (ClientLogin) will be shown
  if (showLoading) return null;

  return (
    <ClientAuthContainer>
      <Formik<RegisterFormValues>
        innerRef={formikRef}
        validationSchema={schema}
        initialValues={{
          fullName: '',
          password: '',
          companyName: '',
          email: '',
        }}
        onSubmit={handleSignup}
      >
        {({
          errors,
          handleSubmit,
          values,
          touched,
          handleChange,
          handleBlur,
        }) => {
          const isSignupEnabled = React.useMemo(() => {
            const isFullNameValid = values.fullName && !errors.fullName;
            const isEmailValid = values.email && !errors.email;
            const isPasswordValid = values.password && !errors.password;
            let enabled = isFullNameValid && isEmailValid && isPasswordValid;

            // sign up with google requires full name field to be valid
            if (isGoogleSignUpFlow) {
              enabled = isFullNameValid;
            }

            // sign up with magic links requires email and full name fields to be valid
            if (enableMagicLinks) {
              if (values.password) {
                enabled = isFullNameValid && isEmailValid && isPasswordValid;
              } else {
                enabled = isFullNameValid && isEmailValid;
              }
            }

            // sign up with email and password requires full name, email and password fields to be valid
            return enabled;
          }, [
            values.fullName,
            values.email,
            values.password,
            errors.fullName,
            errors.email,
            errors.password,
            enableMagicLinks,
            isGoogleSignUpFlow,
          ]);
          return (
            <form
              noValidate
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <div>
                {googleAuthFeatureEnabled && (
                  <>
                    <GoogleAuthButton
                      onClick={() =>
                        SignInWithGoogle(googleAuthState.toString())
                      }
                    >
                      Continue with Google
                    </GoogleAuthButton>
                    <RowDivider mt={0} mb={0}>
                      <BaseTypography
                        fontType="12Medium"
                        style={{
                          color: GraySmall,
                        }}
                      >
                        OR
                      </BaseTypography>
                    </RowDivider>
                  </>
                )}

                <BaseTextField
                  classes={{
                    root: '[&_.MuiInputBase-input]:h-[42px]',
                  }}
                  fullWidth
                  type="text"
                  key="fullName"
                  name="fullName"
                  autoFocus
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullName}
                  label={I18n.get('Full name')}
                  error={Boolean(touched.fullName && errors.fullName)}
                  helperText={(touched.fullName && errors.fullName) || ' '}
                  autoComplete="off"
                />

                {!isGoogleSignUpFlow && (
                  <BaseTextField
                    classes={{
                      root: '[&_.MuiInputBase-input]:h-[42px]',
                    }}
                    fullWidth
                    type="text"
                    key="email"
                    name="email"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    label={I18n.get('Email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={(touched.email && errors.email) || ' '}
                    autoComplete="off"
                  />
                )}

                {!areCompaniesDisabled && (
                  <BaseTextField
                    classes={{
                      root: '[&_.MuiInputBase-input]:h-[42px]',
                    }}
                    fullWidth
                    type="text"
                    key="companyName"
                    name="companyName"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.companyName}
                    label={I18n.get('Company')}
                    placeholder="Optional"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(touched.companyName && errors.companyName)}
                    helperText={
                      (touched.companyName && errors.companyName) || ' '
                    }
                    autoComplete="off"
                    autoFocus={hasGoogleSignedInUser}
                  />
                )}
                {!isGoogleSignUpFlow && (
                  <PasswordField
                    classes={{
                      root: '[&_.MuiInputBase-input]:h-[42px]',
                    }}
                    fullWidth
                    type="password"
                    key="password"
                    name="password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    label={I18n.get('Password')}
                    placeholder={enableMagicLinks ? 'Optional' : ''}
                    error={Boolean(touched.password && errors.password)}
                    helperText={(touched.password && errors.password) || ' '}
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              </div>

              <Button
                size="large"
                className="flex h-12 my-4"
                fullWidth
                htmlId="register-create-account"
                type="submit"
                color="primary"
                variant="contained"
                isLoading={isSignUpLoading || isSendingMagicLink}
                disabled={!isSignupEnabled}
              >
                {enableMagicLinks && !values.password && !isGoogleSignUpFlow
                  ? 'Email me a signup link'
                  : 'Sign up'}
              </Button>
            </form>
          );
        }}
      </Formik>
      <AuthLinkActionButton
        className="flex"
        onClick={handleGoToSignIn}
        linkText="Already have an account?"
      />
    </ClientAuthContainer>
  );
};

export default Register;
